import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from "react-router-dom";
import BeatLoader from "react-spinners/BeatLoader";

const axios = require("axios").default;

const MyMassiveLinks = () => {

    const search = useLocation().search;

    // URL PARAMS
    //    const quableAppType = new URLSearchParams(search).get('quableAppType')
    const token = new URLSearchParams(search).get('token')
    const idProduct = new URLSearchParams(search).get('id')
    const pimInstance = new URLSearchParams(search).get('quableInstance')
    const fieldColor = new URLSearchParams(search).get('fieldColor')
    const pimHost = `https://${pimInstance}.quable.com/api`

    // set OPTIONS
    const [itemsImgs, setitemsImgs] = useState([]);
    const [itemsVariant, setItemsVariant] = useState([]);
    const [resultMessage, setResultMessage] = useState("")

    // UPDATE SELECTIONS
    const [selectedItemsImgs, setSelectedItemsImgs] = useState([]);
    const [selectedItemsVariants, setselectedItemsVariants] = useState([]);
    const [nbLinks, setNbLinks] = useState(0);

    // HANDLERS


    /*
    **   Premier call https://sandbox-zento.quable.com/api/variant?document.id=id_from_url   /!\ pagined
    **   Deuxième call https://sandbox-zento.quable.com/api/assets?documentLinks.article_images_media.origin.id=id_from_url
    **   troisième call https://sandbox-zento.quable.com/api/       letype pour link = sku_images_media
    */

    // 

    const getMedias = async (idProduct) => {

        const mediaImgs = await axios({
            method: "GET",
            url: `${pimHost}/assets/?documentLinks.article_images_media.origin.id=${idProduct}`,
            headers: {
                accept: 'application/ld+json',
                Authorization: `Bearer ${token}`
            },
        })

        const imgs = mediaImgs && mediaImgs.data && mediaImgs.data["hydra:member"]

        console.log("j'ai récup les datas imgproduct ", imgs)
        return (imgs)
    }

    const getVariants = async (idProduct) => {

        const variantsProduct = await axios({
            method: "GET",
            url: `${pimHost}/variants?document.id=${idProduct}`,
            headers: {
                accept: 'application/ld+json',
                Authorization: `Bearer ${token}`
            },
        })
        const variants = variantsProduct && variantsProduct.data && variantsProduct.data["hydra:member"]

        console.log("j'ai récup les datas variants ", variants)
        return variants
    }

    // HOOKS \\
    const handleAddImg = (item) => {

        const isItemSelected = selectedItemsImgs.some((selectedItem) => selectedItem.id === item.id);

        if (isItemSelected) {
            setSelectedItemsImgs((prevSelectedItems) =>
                prevSelectedItems.filter((selectedItem) => selectedItem.id !== item.id)
            );
        } else {
            setSelectedItemsImgs((prevSelectedItems) => [...prevSelectedItems, item]);
        }
    }

    const handleAddVariant = (item) => {

        const isItemSelected = selectedItemsVariants.some((selectedItem) => selectedItem.id === item.id);

        if (isItemSelected) {
            setselectedItemsVariants((prevSelectedItems) =>
                prevSelectedItems.filter((selectedItem) => selectedItem.id !== item.id)
            );
        } else {
            setselectedItemsVariants((prevSelectedItems) => [...prevSelectedItems, item]);
        }
    }

    //ON MOUNT
    useEffect(() => {
        getVariants(idProduct).then((response) => {
            setItemsVariant(response)
        })
        getMedias(idProduct).then((response) => {
            setitemsImgs(response)
        })
    }, [])

    // Enregistrer


    const checkAlreadLinkyExist = async (body) => {

        try {
            const exist = await axios.get(`${pimHost}/links?origin.id=${body.origin.id}&target.id=${body.target.id}&linkType.id=${body.linkType.id}`, {
                headers: {
                    Accept: 'application/hal+json',
                    Authorization: `Bearer ${token}`
                }
            })
            console.log("mon exist ::::::", exist)
            if (exist.data && exist.data.totalItems && exist.data.totalItems > 0)
                return exist.data._embedded.item[0]
            else
                return false
        } catch (error) {
            console.log(error.message)
            console.log(error.request)
            return false
            //throw new Error(`Error: ${error.request}`);
        }
    }

    const linkingDocuments = async (body) => {

        try {
            try {
                const linked = await axios.post(`${pimHost}/links`, body, {
                    headers: {
                        'Content-Type': 'application/ld+json',
                        Accept: 'application/hal+json',
                        Authorization: `Bearer ${token}`
                    }
                })

                //return (`Success: ${linked.data}`)
            } catch (error) {
                throw error;
            }
                        return { success: true, inputData: body }
        } catch (error) {
            console.error(error.message)
            console.error(error.request)
            try {
                    const alreadyExist = await checkAlreadLinkyExist(body)
                
                    if (alreadyExist === false) {
                    return { success: alreadyExist, inputData: body }
                }
                try {
                    console.log(alreadyExist, body)
                    const linked = await axios.put(`${pimHost}/links/${alreadyExist.id}`, body, {
                        headers: {
                            'Content-Type': 'application/ld+json',
                            Accept: 'application/hal+json',
                            Authorization: `Bearer ${token}`
                        }
                    })
                    return { success: true, inputData: body }
                } catch (error) {
                    console.error(error)
                    return { success: false, inputData: body }
                }

            } catch (error) {
                console.error(error)
                return { success: false, inputData: body }
            }
        }
    }



    const handleSaveClick = async () => {

        const bodies = [];
        let setLinks = 0;

        if (selectedItemsVariants.length === 0 || selectedItemsImgs.length === 0)
            return

        selectedItemsVariants.forEach(targetObject => {
            selectedItemsImgs.forEach((targetImgs) => {

                const body = {
                    origin: { id: targetObject.id },
                    target: { id: targetImgs.id },
                    linkType: { id: "sku_images_media" }
                };
                bodies.push(body);
            })
        });

        setResultMessage("Association en cours")


        bodies.sort((a, b) => {
            return a.origin.id.localeCompare(b.origin.id)
        })


        let messageOutput = ""
        for (let index = 0; index < bodies.length; index++) {
            try {
                const result = await linkingDocuments(bodies[index])
                console.log("mon result ::::::", result)
                if (result.success === true) {
                    setLinks += 1;
                    //messageOutput += `${result.inputData.origin.id} \u2194 ${result.inputData.target.id} OK \n`
                } else {
                    messageOutput += `${result.inputData.origin.id} \u2194 ${result.inputData.target.id} NOK \n`
                }
            } catch (error) {
                messageOutput += `${bodies[index].origin.id} \u2194 ${bodies[index].target.id} NOK \n`
            }

        }
        messageOutput += `Association réalisée avec succès sur ${setLinks} combinaisons images / variants. \n`
        setResultMessage(messageOutput)
        const imgsCheckboxes = document.querySelectorAll('input[type="checkbox"]');
        imgsCheckboxes.forEach(checkbox => {
            checkbox.checked = false;
        });
        setSelectedItemsImgs([]);
        setselectedItemsVariants([]);
    };



    useEffect(() => {
        console.log("Mon variantList update :", selectedItemsVariants)
        console.log("Mon imgList update :", selectedItemsImgs)
    }, [selectedItemsImgs, selectedItemsVariants])



    return (
        <div style={{ display: 'flex', height: '100vh', margin: "0px 20px" }}>
            <div style={{ flex: '1', "margin-top": "15px" }}>
                <div style={{ display: 'flex' }}>
                    <label style={{ "font-size": "14px", "font-family": "Poppins, Arial, sans-serif", padding: "5px" }} htmlFor="inputElement">Produit : {idProduct}</label>
                </div>
                {resultMessage && <div style={{ flex: '1', "margin-top": "15px", flexDirection: "column" }}>
                    <div style={{ flex: "1", font: "400 14px \"Open Sans\",sans-serif", marginTop: "25px", marginRight: 10 }}>
                        {resultMessage &&
                            resultMessage.split("\n").filter(line => line && line.length > 0).map((line, index) => {
                                let colorApply = "black"
                                if (line.includes("NOK")) {
                                    colorApply = "#FF8787"
                                } else if (line.includes("succès")) {
                                    colorApply = "#38D9A9"
                                }

                                return (
                                    <React.Fragment key={index}>
                                        <p style={{ backgroundColor: "white", padding: 5, margin: 0, color: colorApply }}>{line}
                                            <br />
                                        </p>
                                    </React.Fragment>)


                            })

                        }
                    </div>
                </div>}
                <div style={{ display: 'flex' }}>
                    <div style={{ flex: "1", backgroundColor: '#ffffff', marginTop: '20px', "overflowY": "auto", height: "300px", border: "1px solid #000000", marginRight: "5px" }}>
                        <ul style={{ display: "grid", gridTemplateColumns: "repeat(auto-fill, minmax(100px, 1fr))", gap: "10px", marginTop: 0, marginBlockEnd: 0, paddingInlineStart: 0 }}>
                            {itemsImgs && itemsImgs.map((item) => (
                                <li style={{ color: "black", display: "flex", flexDirection: "column", alignItems: "center", padding: 5, minHeight: "50px", cursor: "pointer" }} key={item.id} >
                                    <img src={item.thumbnailUrl} alt="thumbnail" width={80} height={80} />
                                    <input style={{ marginTop: "10px" }} type="checkbox" onChange={(e) => handleAddImg(item, e.target.checked)} />
                                </li>

                            ))}
                        </ul>

                    </div>
                    <div style={{ flex: '1', display: "flex", flexDirection: "column", marginLeft: "5px" }}>
                        <div style={{ backgroundColor: '#ffffff', marginTop: '20px', "overflowY": "auto", height: "300px", border: "1px solid #000000" }}>
                            <ul style={{ marginTop: 0, marginBlockEnd: 0, paddingInlineStart: 0 }}>
                                {itemsVariant && itemsVariant.map((item) =>  {  

                                    // Ma condition consiste à :
                                    // Si l'attribut fieldColor est une chaine de caractère, j'affiche la valeur de l'attribut
                                    // Si l'attribut fieldColor est un objet, je vérifie si c'est un tableau, si oui je vérifie si le tableau contient des labels, si oui j'affiche le label en français
                                    // Si l'attribut fieldColor est un objet, je vérifie si c'est un objet simple, si oui j'affiche la valeur de l'attribut
                                    // Si l'attribut fieldColor n'est pas un objet, je n'affiche rien
                                    // Si l'attribut fieldColor n'est pas défini, je n'affiche rien
    
                                    return (
                                    <li style={{ color: "blach", display: "flex", alignItems: "center", padding: 5, cursor: "pointer" }} key={item.id} >
                                        <input type="checkbox" onChange={(e) => handleAddVariant(item, e.target.checked)} />
                                        <div style={{ color: "black", marginLeft: "10px", font: "400 12px \"Open Sans\",sans-serif" }}>Variant du produit sku : {`${item.id}`} {(item.attributes && item.attributes[`${fieldColor}`]) &&
                                        (typeof item.attributes[`${fieldColor}`] === "string" ? `- ${item.attributes[`${fieldColor}`]}`
                                        : (typeof item.attributes[`${fieldColor}`] === "object" ?
                                        (((item.attributes[`${fieldColor}`].length > 0 && item.attributes[`${fieldColor}`][0].labels && (`- ${item.attributes[`${fieldColor}`][0].labels["fr_FR"]}`))
                                        || (`- ${item.attributes[`${fieldColor}`]["fr_FR"]}`)) || "")
                                        : (``)))}</div>
                                    </li>
                                )})}
                            </ul>
                        </div>
                    </div>
                </div>
                <div style={{ display: "flex", justifyContent: "space-between", padding: "5px", marginTop: "25px" }}>
                    <div></div>

                    <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                        {resultMessage === "Association en cours" ? <BeatLoader color="#556ee6" /> : null}
                        <button style={{ cursor: "pointer", minWidth: "75px", textTransform: "uppercase", padding: "9px", fontWeight: "bold", minHeight: "30px", background: "#f80", border: "0px", margin: "0px", borderRadius: "3px", color: "#FFF" }} onClick={async () => await handleSaveClick()}>Enregistrer</button>
                    </div>



                </div>
            </div>
        </div >
    );
};

export default MyMassiveLinks;